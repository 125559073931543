import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getAllLiquidityPairs } from '@context/api/allLiquidityPairs';
import {
  ETH_TESTNET_PROVIDERS,
  VERSE_ETH_PAIR_ADDRESS,
} from '@context/constants';
import { useTrackedState } from '@context/store';

import { findProvider } from '@helpers/utils';

import { getMatchingFarm } from '@views/Farms/helpers/getMatchingFarm';

import testnetPairs from '../../mocks/bitcoincom_seth_pairs.json';

const LiquidityPairsContext = createContext<{
  liquidityPairs?: ILiquidityPair[];
  hasError?: boolean;
}>({});

const LiquidityPairsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [liquidityPairs, setLiquidityPairs] = useState<ILiquidityPair[]>();
  const { provider: exchangeProvider } = useTrackedState();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const fetchLiquidityPairs = async () => {
      setLiquidityPairs(undefined);
      setHasError(false);
      const provider = findProvider(exchangeProvider);

      if (!provider.isDeFi || !provider.subgraphUrl) return;
      try {
        const response = ETH_TESTNET_PROVIDERS.includes(exchangeProvider)
          ? testnetPairs
          : await getAllLiquidityPairs(provider.subgraphUrl);

        const sortedByLiquidity = response.sort(
          (pair1: { reserveUSD: string }, pair2: { reserveUSD: string }) => {
            if (parseFloat(pair1.reserveUSD) < parseFloat(pair2.reserveUSD))
              return 1;
            if (parseFloat(pair1.reserveUSD) > parseFloat(pair2.reserveUSD))
              return -1;
            return 0;
          },
        );

        const sortedPairs = sortedByLiquidity
          .sort((a: { id: string }, b: { id: string }) => {
            const matchingFarm1 = getMatchingFarm(a.id, exchangeProvider);
            const matchingFarm2 = getMatchingFarm(b.id, exchangeProvider);

            if (matchingFarm1 && !matchingFarm2) return -1;
            if (!matchingFarm1 && matchingFarm1) return 1;
            return 0;
          })
          .sort((a: { id: string }, b: { id: string }) => {
            const isDefaultFarmA =
              a.id === VERSE_ETH_PAIR_ADDRESS.toLowerCase();
            const isDefaultFarmB =
              b.id === VERSE_ETH_PAIR_ADDRESS.toLowerCase();

            if (isDefaultFarmA) return -1;
            if (isDefaultFarmB) return 1;
            return 0;
          });

        setLiquidityPairs(sortedPairs);
      } catch {
        setHasError(true);
        setLiquidityPairs(undefined);
      }
    };
    fetchLiquidityPairs();
  }, [exchangeProvider]);

  const value = useMemo(() => {
    return {
      liquidityPairs,
      hasError,
    };
  }, [liquidityPairs, hasError]);

  return (
    <LiquidityPairsContext.Provider value={value}>
      {children}
    </LiquidityPairsContext.Provider>
  );
};

export const useLiquidityPairs = () => useContext(LiquidityPairsContext);

export default LiquidityPairsProvider;
